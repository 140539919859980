.sign-layout {
  background-color: #f3f3f3;
  height: 100%;
  width: 100%;
  display: table;
}

.sign-layout.contact-registrations-layout {
  min-width: 410px;
}

.sign-layout .center {
  max-width: none;
  background-color: #f3f3f3;
  display: table-cell;
  vertical-align: middle;
}

.sign-layout .footer {
  width: 100%;
  text-align: center;
  font-size: 13px;
  background-color: #f3f3f3;
  height: 28px;
  display: table-row;
}

.sign-layout .footer .link_footer {
  text-decoration: unset;
}

.sign-layout .footer .link_footer:not(:hover) {
  color: #13171e;
}

