.sign-layout
  background-color: #F3F3F3
  height: 100%
  width: 100%
  display: table

  &.contact-registrations-layout
    min-width: 410px

  .center
    max-width: none
    background-color: #F3F3F3
    display: table-cell
    vertical-align: middle

  .footer
    width: 100%
    text-align: center
    font-size: 13px
    background-color: #F3F3F3
    height: 28px
    display: table-row

    .link_footer
      text-decoration: unset

      &:not(:hover)
        color: #13171e
