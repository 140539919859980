body,
html {
  font-family: "Open Sans",sans-serif;
  font-weight: lighter;
  margin: 0;
  height: 100%;
}

.page__color_black {
  background-color: #373a3e;
  color: #fff;
}

.hidden {
  display: none !important;
}

