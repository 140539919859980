.center {
  margin: 0 auto;
  max-width: 1188px;
  padding: 0 15px;
  box-sizing: border-box;
}

.center-wrap {
  min-width: 320px;
}

