$empty-block-height: 32px
$form-holder-width: 344px
$reset-password-form-width: 450px

// reset focus submit btn
\:focus
  outline: 0

.form__layout
  margin: 20px auto
  font-family: "Open Sans", sans-serif
  font-weight: lighter
  max-width: 380px

  &.reset-password-form
    max-width: 486px

  .form__shadow
    box-shadow: 0px 0px 1px 1px #dcdcdc
    border-radius: 8px

  .form__holder
    width: $form-holder-width
    background-color: #e6e9ee
    padding: 18px
    border-top-right-radius: 8px
    border-top-left-radius: 8px

    &.with-bottom-border-radius
      border-bottom-right-radius: 8px
      border-bottom-left-radius: 8px

    &.password-form
      width: $reset-password-form-width
      border-bottom-right-radius: 8px
      border-bottom-left-radius: 8px

    .user-list-for-password-recovery
      margin-bottom: 10px

      .user-checkbox
        margin-bottom: 2px

    .choose-button
      padding-top: 5px
      text-align: center

    .form__logo
      width: 100%
      text-align: center

      img
        max-height: 50px
        max-width: 310px

      .title
        font-size: 18px
        padding: 8px 0

    .form__row
      .error
        display: none

      &.centered
        text-align: center

      &.with-multiple-line-errors
        .field_with_errors
          .error
            height: auto

      .field_with_errors
        label
          color: #8c0000
        input[type=text],
        input[type=email],
        input[type=tel]
          border: 1px solid #8c0000

        .form__input
          margin-bottom: 5px
          border-radius: 8px

        .error
          color: #8c0000
          display: block
          font-size: 12px
          height: 10px
          line-height: 11px
          margin-bottom: 5px

        .custom-checkbox
          display: flex

          .checkbox
            border: 1px solid #8c0000

      .empty-block
        height: $empty-block-height

      .form__input
        width: 100%
        padding: 8px
        margin: 5px 0 15px
        box-sizing: border-box
        border: 1px solid #b5c0cd
        border-radius: 8px

        &:focus
          outline: none

      label
        font-size: 13px
        color: #71727d

    .custom-checkbox
      margin: 5px 0 20px

      label
        float: left
        display: block

    .btn
      background: #4dad33
      color: #fff
      border: 1px solid #4dad33
      border-radius: 8px
      font-size: 13px
      padding: 0 10px
      cursor: pointer
      height: 30px
      line-height: 30px
      display: inline-block
      transition: background-color 0.2s, border-color 0.2s, color 0.2s

      &:hover
        color: #4dad33
        background: #fff

      &.btn-with-ok
        padding: 0 20px

        &.hidden
          display: none

        &:before
          content: '\f00c'
          font-family: FontAwesome
          font-style: normal
          font-weight: lighter
          padding-right: 15px

        &:hover
          background-position: 10px -30px
          background-color: white !important
          color: #4dad33

    .form-modal-custom-parameters-list
      .form-modal-custom-parameters-item
        &.field_with_errors
          label
            color: #8c0000

          .error
            color: #8c0000
            display: block
            font-size: 12px
            height: 10px
            line-height: 11px
            margin-bottom: 5px

          &.ftstring, &.ftdatetime, &.ftdate
            input
              border: 1px solid #8c0000
              margin-bottom: 5px

          &.ftselect, &.ftmultiselect
            .custom-select
              border: 1px solid #8c0000
              margin-bottom: 5px

        label
          font-size: 13px
          color: #71727d

        &.ftstring, &.ftdatetime, &.ftdate
          input
            width: 100%
            padding: 8px
            margin: 5px 0 15px
            box-sizing: border-box
            border: 1px solid #b5c0cd

            &:focus
              outline: none

        &.ftdatetime
          .pull-left
            &:nth-child(5)
              margin-top: 6px

        &.ftselect, &.ftmultiselect
          .custom-select
            margin: 5px 0 15px
            font-weight: 400

            .selected
              padding: 8px 20px 14px 10px

      .date-picker-container
        width: 60%

  .footer-form
    line-height: 50px
    background-color: #fff
    position: relative
    left: -18px
    padding: 0 18px
    bottom: -18px
    width: 100%
    border-bottom-left-radius: 8px
    border-bottom-right-radius: 8px

    .recaptcha-block
      padding: 10px 0
      line-height: 15px

      .error-message-line
        color: #8c0000
        display: block
        font-size: 12px

    .required
      font-size: 13px
      color: #71727d

    .accept-personal-data-block
      line-height: 15px
      padding-top: 10px
      margin-bottom: 10px

      .processing-personal-data-url
        text-decoration: none


  .password-holder
    background-color: #fff
    text-align: center
    min-height: 70px
    max-height: 150px
    padding: 0 18px
    border-bottom-left-radius: 8px
    border-bottom-right-radius: 8px

    .recover-link, .new-contact-link
      display: inline-block
      margin: 24px 0
      font-size: 13px
      font-weight: 500

      i.fa
        color: #71727d
        margin: 0 5px

      a
        color: #71727d
        text-decoration: none

        &:hover
          color: #4dad33

  .notification-line
    display: none
    text-align: center
    padding: 20px 18px
    font-size: 13px
    background: #eee
    position: relative

    &.alert
      background: #ffdbdb
      color: #8c0000
      line-height: 20px

    &.notice
      background: #C4F3C8
      color: #008C10

    .close-alert
      position: absolute
      top: 16px
      right: 15px
      width: 20px
      height: 20px
      background: url("images/base/close-pink.gif") no-repeat scroll center center transparent

    .close-notice
      position: absolute
      top: 16px
      right: 15px
      width: 20px
      height: 20px
      background: url("images/base/close-green.gif") no-repeat scroll center center transparent

    &.block
      margin-top: 10px
      display: block

$submit-btn-text: #fff
$submit-btn: #4dad33
$submit-btn-hover: #fff
$submit-btn-trans-time: .2s

.submit-btn
  cursor: pointer
  background: $submit-btn
  color: $submit-btn-text
  border: 1px solid $submit-btn

  font-size: 13px
  padding: 0 10px

  height: 30px
  line-height: 30px
  display: inline-block

  transition: background-color $submit-btn-trans-time, border-color $submit-btn-trans-time, color $submit-btn-trans-time

  &:hover
    color: $submit-btn
    background: $submit-btn-hover

  &.submit-btn-with-ok:before
    content: "\f00c"
    font-family: FontAwesome
    font-style: normal
    font-weight: normal
    padding-right: 0.5em

@media all and (max-width: 480px)
  .form__layout .form__holder.password-form
    width: auto

@media all and (max-width: 430px)
  .form__layout .form__holder
    width: auto

@media all and (max-width: 360px)
  .form__layout .footer-form
    text-align: center

    .required
      display: none

@media all and (max-width: 300px)
  .form__layout .password-holder
    .recover-link, .new-contact-link
      margin: 7px 0
