.p-err {
  padding: 0 0 30px 0;
  overflow: hidden;
}

.p-err .sign-out-link {
  float: right;
  font-size: 18px;
  color: #fff;
  margin: 2em 5em 0 0;
}

.p-err .sign-out-link:hover .fa {
  color: #4dad33;
}

.p-err__logo-img {
  display: block;
  height: 30px;
}

.p-err__content {
  width: 460px;
  position: relative;
  padding: 145px 0 0 0;
}

.p-err__content.deactivated_account__content {
  padding: 15px 0 10px 0;
  font-size: 40px;
}

.p-err__content.deactivated_account__content a {
  color: #4dad33;
}

.p-err__preview {
  position: absolute;
  left: 532px;
  bottom: -97px;
  display: block;
}

.p-err__preview.deactivated_account__preview {
  bottom: 5px;
}

.p-err__inside {
  background-color: #2c2f34;
  padding: 40px 0 79px 0;
}

.p-err__inside.deactivated_account__inside {
  padding: 40px 0 0 0;
}

.p-err__title {
  font-size: 45px;
  line-height: 1.2em;
  letter-spacing: -0.04em;
  margin-bottom: 13px;
}

.p-err__title.deactivated_account__title {
  font-size: 40px;
}

.p-err__desc {
  font-size: 15px;
  line-height: 1.6em;
  color: #ccc;
  margin-bottom: 35px;
}

.p-err__desc a {
  color: #fff;
}

.err__error-container {
  background-color: #2c2f34;
  padding-top: 40px;
}

.err__error-container .err__error-content {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1188px;
  padding: 0 15px;
}

.err__error-container .err__error-content .err__header img {
  display: block;
  height: 30px;
}

.err__error-container .err__error-content .err__main-content {
  display: flex;
  flex-direction: row;
  padding: 60px 0 15px 0;
  gap: 0 10px;
}

.err__error-container .err__error-content .err__main-content .err__text-content {
  display: flex;
  flex-direction: column;
  align-self: center;
  flex-basis: 450px;
}

.err__error-container .err__error-content .err__main-content .err__text-content .err__title {
  font-size: 45px;
  letter-spacing: -0.04em;
  line-height: 1.2em;
  margin-bottom: 13px;
}

.err__error-container .err__error-content .err__main-content .err__text-content .err__desc {
  color: #ccc;
  font-size: 15px;
  line-height: 1.6em;
  margin-bottom: 35px;
}

.err__error-container .err__error-content .err__main-content .err__image-content {
  text-align: center;
}

.err__error-container .err__error-content .err__main-content .err__image-content img {
  max-width: 100%;
}

@media (max-width: 1188px)and (min-width: 700px) {
  .err__error-container {
    padding-top: 10px;
  }

  .err__error-container .err__error-content .err__main-content {
    padding: 20px 0 5px 0;
  }
}

@media (max-width: 700px) {
  .err__error-container .err__error-content .err__main-content {
    flex-wrap: wrap;
  }

  .err__error-container .err__error-content .err__main-content .err__text-content,
  .err__error-container .err__error-content .err__main-content .err__image-content {
    flex-basis: 100%;
  }
}

