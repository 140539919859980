:root {
  --account-system-color: #4dad33;
}

.new-btn,
.submit-btn,
.status-btn.for-status-active {
  background-color: var(--account-system-color);
  border-color: var(--account-system-color);
}

.new-btn.btn-minus:hover:before,
.new-btn.btn-minus:hover:after {
  border-color: var(--account-system-color) !important;
}

.sidebar-menu li:hover a,
.sidebar-menu li:hover,
.custom-select--li:hover,
.custom-select--li.hovered,
.main-paginator .prev a:hover,
.main-paginator .next a:hover,
.main-paginator .first a:hover,
.main-paginator .last a:hover,
.sibling-period-btn:hover,
.okdesk-spoiler--btn:hover,
.knowledge-base--item-button:hover {
  background-color: var(--account-system-color) !important;
  border-color: var(--account-system-color) !important;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default>div,
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current>div,
.sign-layout .btn.btn-with-ok {
  background-color: var(--account-system-color) !important;
  border-color: var(--account-system-color) !important;
}

a:hover {
  color: var(--account-system-color);
}

.header-toolbar-link:hover,
.header-toolbar-link:hover .fa,
.changeable-via-popup .changeable_link a:hover,
.changeable-via-popup .changeable_link i:hover,
.changeable-via-popup .content a:hover,
.create-issue-prop-btn .fa:hover,
.main-paginator .current,
.main-paginator a:hover,
.ckeditor-content a:hover,
.knowledges-content-block .knowledge-block:hover .icon,
.knowledges-content-block .knowledge-block .controls a:hover,
.phone-calls-table a:hover,
.phone-calls-table a i:hover,
.phone-call-preview a:hover,
.phone-call-preview a i:hover,
.comment-icons i.fa:hover,
.teamviewer-sessions-table .teamviewer-sessions-table-column table .teamviewer-session-table-cell .edit-teamviewer-session-table-settings-btn:hover,
.company-agreement:hover,
.parent-title:hover,
.equipment-card-icon {
  color: var(--account-system-color) !important;
}

.submit-btn:hover {
  background: none;
  color: var(--account-system-color);
}

.main-breadcrumbs .fa,
.main-breadcrumbs a:hover,
.best_in_place .fa:hover,
.sign-layout .btn.btn-with-ok:hover,
.editable-block .icon:hover,
.main-filters .toggle-header .fa,
.header-xlxs-btn .fa,
.header-table-view-btn a:hover .fa,
.header-calendar-view-btn a:hover .fa,
.header-map-view-btn a:hover .fa,
.header-table-view-btn .active .fa,
.header-calendar-view-btn .active .fa,
.header-map-view-btn .active .fa,
.issues-table .issue-show-preview-btn:hover,
.preview-description-btn:hover,
.issues-table .edit-issue-table-settings-btn:hover,
.week-assignee .fa.fa-cog:hover,
.week-assignee .fa.fa-cog.active,
.custom-multiple-checkbox ul li:hover,
.custom-multiple-checkbox ul li:hover .draggable,
.issues-table td.issue-table-cell.number a:hover,
.issue-ttitle .number a:hover,
.column-filter-icon.active,
.sign-layout .recover-link a:hover,
.sign-layout .new-contact-link a:hover,
.sign-layout .footer .link_footer:hover,
.sidebar-main-menu>.sidebar-main-menu-element:hover .fa,
.sidebar-main-menu>.sidebar-main-menu-element:hover .element-text,
.sidebar-main-menu-switch-container .fa:hover,
.knowledge-sidebar-menu .knowledge-menu-item.folder-type span.knowledge-menu-item-text:hover,
.knowledge-sidebar-menu .knowledge-menu-item.file-type span:hover,
.issue-teamviewer-session-close:hover,
.accept-personal-data-block .processing-personal-data-url,
.reset-column-filter-icon:hover,
.issues-table td.issue-table-cell .parent-issue-table-link:hover,
.edit-sidebar-columns-btn .fa:hover,
.knowledge-base-views-toggler .view-button.active,
.knowledge-base-table .section-row:hover .icon-column,
.knowledge-base-table .section-row:hover .title-block,
.knowledge-base-table .article-row:hover .icon-column,
.knowledge-base-table .article-row:hover .title-block,
.knowledge-base-table .control-icon:hover,
.knowledge-base-views-toggler .view-button:hover,
.contact-authentication-code-block .fa:hover,
.work-type-list-toggle:hover,
.card-attributes-list .telegram-username-form span[data-role=bip-value]:not([data-blank=true]):hover {
  color: var(--account-system-color) !important;
}

.new-btn:hover,
.submit-btn:hover:enabled,
.status-btn.for-status-active:hover {
  color: var(--account-system-color) !important;
  border-color: var(--account-system-color);
}

.new-btn.new-btn-plus:hover:before,
.new-btn.new-btn-plus:hover:after,
.xdsoft_datetimepicker .xdsoft_calendar td>div:hover,
.custom-multiple-checkbox ul li:hover,
.okdesk-tabs--item:not(.active):hover {
  border-color: var(--account-system-color) !important;
}

.okdesk-tabs--item:hover,
.multilevel-select .multilevel-select--li:hover,
.atwho-view .atwho-view-ul li.cur:not(.atwho-header) {
  background-color: var(--account-system-color);
}

.preview-btn i.fa.fa-search-plus:hover {
  color: var(--account-system-color) !important;
}

.new-btn.with-svg-icon:hover svg {
  fill: var(--account-system-color) !important;
}

.new-btn.with-svg-icon:hover svg path {
  stroke: var(--account-system-color) !important;
}

