:focus {
  outline: 0;
}

.form__layout {
  margin: 20px auto;
  font-family: "Open Sans",sans-serif;
  font-weight: lighter;
  max-width: 380px;
}

.form__layout.reset-password-form {
  max-width: 486px;
}

.form__layout .form__shadow {
  box-shadow: 0px 0px 1px 1px #dcdcdc;
  border-radius: 8px;
}

.form__layout .form__holder {
  width: 344px;
  background-color: #e6e9ee;
  padding: 18px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.form__layout .form__holder.with-bottom-border-radius {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.form__layout .form__holder.password-form {
  width: 450px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.form__layout .form__holder .user-list-for-password-recovery {
  margin-bottom: 10px;
}

.form__layout .form__holder .user-list-for-password-recovery .user-checkbox {
  margin-bottom: 2px;
}

.form__layout .form__holder .choose-button {
  padding-top: 5px;
  text-align: center;
}

.form__layout .form__holder .form__logo {
  width: 100%;
  text-align: center;
}

.form__layout .form__holder .form__logo img {
  max-height: 50px;
  max-width: 310px;
}

.form__layout .form__holder .form__logo .title {
  font-size: 18px;
  padding: 8px 0;
}

.form__layout .form__holder .form__row .error {
  display: none;
}

.form__layout .form__holder .form__row.centered {
  text-align: center;
}

.form__layout .form__holder .form__row.with-multiple-line-errors .field_with_errors .error {
  height: auto;
}

.form__layout .form__holder .form__row .field_with_errors label {
  color: #8c0000;
}

.form__layout .form__holder .form__row .field_with_errors input[type=text],
.form__layout .form__holder .form__row .field_with_errors input[type=email],
.form__layout .form__holder .form__row .field_with_errors input[type=tel] {
  border: 1px solid #8c0000;
}

.form__layout .form__holder .form__row .field_with_errors .form__input {
  margin-bottom: 5px;
  border-radius: 8px;
}

.form__layout .form__holder .form__row .field_with_errors .error {
  color: #8c0000;
  display: block;
  font-size: 12px;
  height: 10px;
  line-height: 11px;
  margin-bottom: 5px;
}

.form__layout .form__holder .form__row .field_with_errors .custom-checkbox {
  display: flex;
}

.form__layout .form__holder .form__row .field_with_errors .custom-checkbox .checkbox {
  border: 1px solid #8c0000;
}

.form__layout .form__holder .form__row .empty-block {
  height: 32px;
}

.form__layout .form__holder .form__row .form__input {
  width: 100%;
  padding: 8px;
  margin: 5px 0 15px;
  box-sizing: border-box;
  border: 1px solid #b5c0cd;
  border-radius: 8px;
}

.form__layout .form__holder .form__row .form__input:focus {
  outline: none;
}

.form__layout .form__holder .form__row label {
  font-size: 13px;
  color: #71727d;
}

.form__layout .form__holder .custom-checkbox {
  margin: 5px 0 20px;
}

.form__layout .form__holder .custom-checkbox label {
  float: left;
  display: block;
}

.form__layout .form__holder .btn {
  background: #4dad33;
  color: #fff;
  border: 1px solid #4dad33;
  border-radius: 8px;
  font-size: 13px;
  padding: 0 10px;
  cursor: pointer;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  transition: background-color .2s,border-color .2s,color .2s;
}

.form__layout .form__holder .btn:hover {
  color: #4dad33;
  background: #fff;
}

.form__layout .form__holder .btn.btn-with-ok {
  padding: 0 20px;
}

.form__layout .form__holder .btn.btn-with-ok.hidden {
  display: none;
}

.form__layout .form__holder .btn.btn-with-ok:before {
  content: "";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: lighter;
  padding-right: 15px;
}

.form__layout .form__holder .btn.btn-with-ok:hover {
  background-position: 10px -30px;
  background-color: #fff !important;
  color: #4dad33;
}

.form__layout .form__holder .form-modal-custom-parameters-list .form-modal-custom-parameters-item.field_with_errors label {
  color: #8c0000;
}

.form__layout .form__holder .form-modal-custom-parameters-list .form-modal-custom-parameters-item.field_with_errors .error {
  color: #8c0000;
  display: block;
  font-size: 12px;
  height: 10px;
  line-height: 11px;
  margin-bottom: 5px;
}

.form__layout .form__holder .form-modal-custom-parameters-list .form-modal-custom-parameters-item.field_with_errors.ftstring input,
.form__layout .form__holder .form-modal-custom-parameters-list .form-modal-custom-parameters-item.field_with_errors.ftdatetime input,
.form__layout .form__holder .form-modal-custom-parameters-list .form-modal-custom-parameters-item.field_with_errors.ftdate input {
  border: 1px solid #8c0000;
  margin-bottom: 5px;
}

.form__layout .form__holder .form-modal-custom-parameters-list .form-modal-custom-parameters-item.field_with_errors.ftselect .custom-select,
.form__layout .form__holder .form-modal-custom-parameters-list .form-modal-custom-parameters-item.field_with_errors.ftmultiselect .custom-select {
  border: 1px solid #8c0000;
  margin-bottom: 5px;
}

.form__layout .form__holder .form-modal-custom-parameters-list .form-modal-custom-parameters-item label {
  font-size: 13px;
  color: #71727d;
}

.form__layout .form__holder .form-modal-custom-parameters-list .form-modal-custom-parameters-item.ftstring input,
.form__layout .form__holder .form-modal-custom-parameters-list .form-modal-custom-parameters-item.ftdatetime input,
.form__layout .form__holder .form-modal-custom-parameters-list .form-modal-custom-parameters-item.ftdate input {
  width: 100%;
  padding: 8px;
  margin: 5px 0 15px;
  box-sizing: border-box;
  border: 1px solid #b5c0cd;
}

.form__layout .form__holder .form-modal-custom-parameters-list .form-modal-custom-parameters-item.ftstring input:focus,
.form__layout .form__holder .form-modal-custom-parameters-list .form-modal-custom-parameters-item.ftdatetime input:focus,
.form__layout .form__holder .form-modal-custom-parameters-list .form-modal-custom-parameters-item.ftdate input:focus {
  outline: none;
}

.form__layout .form__holder .form-modal-custom-parameters-list .form-modal-custom-parameters-item.ftdatetime .pull-left:nth-child(5) {
  margin-top: 6px;
}

.form__layout .form__holder .form-modal-custom-parameters-list .form-modal-custom-parameters-item.ftselect .custom-select,
.form__layout .form__holder .form-modal-custom-parameters-list .form-modal-custom-parameters-item.ftmultiselect .custom-select {
  margin: 5px 0 15px;
  font-weight: 400;
}

.form__layout .form__holder .form-modal-custom-parameters-list .form-modal-custom-parameters-item.ftselect .custom-select .selected,
.form__layout .form__holder .form-modal-custom-parameters-list .form-modal-custom-parameters-item.ftmultiselect .custom-select .selected {
  padding: 8px 20px 14px 10px;
}

.form__layout .form__holder .form-modal-custom-parameters-list .date-picker-container {
  width: 60%;
}

.form__layout .footer-form {
  line-height: 50px;
  background-color: #fff;
  position: relative;
  left: -18px;
  padding: 0 18px;
  bottom: -18px;
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.form__layout .footer-form .recaptcha-block {
  padding: 10px 0;
  line-height: 15px;
}

.form__layout .footer-form .recaptcha-block .error-message-line {
  color: #8c0000;
  display: block;
  font-size: 12px;
}

.form__layout .footer-form .required {
  font-size: 13px;
  color: #71727d;
}

.form__layout .footer-form .accept-personal-data-block {
  line-height: 15px;
  padding-top: 10px;
  margin-bottom: 10px;
}

.form__layout .footer-form .accept-personal-data-block .processing-personal-data-url {
  text-decoration: none;
}

.form__layout .password-holder {
  background-color: #fff;
  text-align: center;
  min-height: 70px;
  max-height: 150px;
  padding: 0 18px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.form__layout .password-holder .recover-link,
.form__layout .password-holder .new-contact-link {
  display: inline-block;
  margin: 24px 0;
  font-size: 13px;
  font-weight: 500;
}

.form__layout .password-holder .recover-link i.fa,
.form__layout .password-holder .new-contact-link i.fa {
  color: #71727d;
  margin: 0 5px;
}

.form__layout .password-holder .recover-link a,
.form__layout .password-holder .new-contact-link a {
  color: #71727d;
  text-decoration: none;
}

.form__layout .password-holder .recover-link a:hover,
.form__layout .password-holder .new-contact-link a:hover {
  color: #4dad33;
}

.form__layout .notification-line {
  display: none;
  text-align: center;
  padding: 20px 18px;
  font-size: 13px;
  background: #eee;
  position: relative;
}

.form__layout .notification-line.alert {
  background: #ffdbdb;
  color: #8c0000;
  line-height: 20px;
}

.form__layout .notification-line.notice {
  background: #c4f3c8;
  color: #008c10;
}

.form__layout .notification-line .close-alert {
  position: absolute;
  top: 16px;
  right: 15px;
  width: 20px;
  height: 20px;
  background: url("../../../images/base/close-pink.gif") no-repeat scroll center center rgba(0,0,0,0);
}

.form__layout .notification-line .close-notice {
  position: absolute;
  top: 16px;
  right: 15px;
  width: 20px;
  height: 20px;
  background: url("../../../images/base/close-green.gif") no-repeat scroll center center rgba(0,0,0,0);
}

.form__layout .notification-line.block {
  margin-top: 10px;
  display: block;
}

.submit-btn {
  cursor: pointer;
  background: #4dad33;
  color: #fff;
  border: 1px solid #4dad33;
  font-size: 13px;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  transition: background-color .2s,border-color .2s,color .2s;
}

.submit-btn:hover {
  color: #4dad33;
  background: #fff;
}

.submit-btn.submit-btn-with-ok:before {
  content: "";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  padding-right: .5em;
}

@media all and (max-width: 480px) {
  .form__layout .form__holder.password-form {
    width: auto;
  }
}

@media all and (max-width: 430px) {
  .form__layout .form__holder {
    width: auto;
  }
}

@media all and (max-width: 360px) {
  .form__layout .footer-form {
    text-align: center;
  }

  .form__layout .footer-form .required {
    display: none;
  }
}

@media all and (max-width: 300px) {
  .form__layout .password-holder .recover-link,
  .form__layout .password-holder .new-contact-link {
    margin: 7px 0;
  }
}

